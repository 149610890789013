var FindSet = {SUN:0, MON:0, TUE:0, WED:0, THU:0, FRI:0, SAT:0},
    map,
    map2,
    user_lat,
    user_long,
    marker,
    map_bounds,
    markers_map = new Array(),
    directionsDisplay,
    directionsService = new google.maps.DirectionsService(),
    weather_attempt = 0,
    forecast_attempt = 0,
    mapmotoStyle =[{"featureType":"all","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"administrative.country","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"administrative.province","elementType":"geometry.fill","stylers":[{"visibility":"on"}]},{"featureType":"administrative.locality","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"administrative.neighborhood","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"administrative.land_parcel","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"landscape.man_made","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"landscape.natural.landcover","elementType":"geometry.fill","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"road.highway.controlled_access","elementType":"geometry.fill","stylers":[{"color":"#e2931e"}]},{"featureType":"road.highway.controlled_access","elementType":"geometry.stroke","stylers":[{"color":"#e2931e"}]},{"featureType":"road.arterial","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#337AB7"}]}];

function get_location() {
  if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
          initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          map.setCenter(initialLocation);

        marker = new google.maps.Marker({
                  position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
                  title:'you are here!',
                  icon:"img/you_here.png",
                  map: map
                });
      });
    }
    else {}
}

//This is the janker used to build maps according to settings for now
function makeMap($page, $track_lat, $track_long) {

    /** MAIN MAP ON HOMEPAGE BEGIN **/
    if($page=='home') {
        get_location();
        map = new google.maps.Map(document.getElementById('home-map'), {
            center: {lat: user_lat, lng: user_long},
            zoom: 9,
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
        });

        map.setOptions({styles: mapmotoStyle});

        google.maps.event.addListener(map, 'tilesloaded', function () {
            map_bounds = map.getBounds();
            get_icons(false);
        });

    }
    /** MAIN MAP ON HOMEPAGE END **/



    /** MAIN MAP ON HOMEPAGE BEGIN **/
    if($page=='in') {
        map = new google.maps.Map(document.getElementById('state-map'), {
            center: {lat: $track_lat, lng: $track_long},
            zoom: 8,
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
        });

        map.setOptions({styles: mapmotoStyle});

        google.maps.event.addListener(map, 'tilesloaded', function () {
            map_bounds = map.getBounds();
            get_icons(false);
        });
    }
    /** MAIN MAP ON HOMEPAGE END **/





    /** MAIN MAP ON FIND PAGE BEGIN **/
    if($page=='find') {
        get_location();
        map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: user_lat, lng: user_long},
            zoom: 9,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT
            },

            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
        });

        map.setOptions({styles: mapmotoStyle});

        google.maps.event.addListener(map, 'tilesloaded', function () {
            map_bounds = map.getBounds();
            get_icons(true);
        });
    }
    /** MAIN MAP ON FIND PAGE END **/

    /** TRACK PROFILE PAGE BEGIN **/
    if($page=='profile') {

        directionsDisplay = new google.maps.DirectionsRenderer();

        map = new google.maps.Map(document.getElementById('top-map'), {
            center: {lat: $track_lat, lng: $track_long},
            zoom: 13,
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false
        });

        map.setOptions({styles: mapmotoStyle});

        directionsDisplay.setMap(map);

        directionsDisplay.setPanel(document.getElementById("directionsDiv"));

        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var start = {lat: position.coords.latitude, lng: position.coords.longitude};
                var end = {lat: $track_lat, lng: $track_long};
                var request = {
                    origin:start,
                    destination:end,
                    travelMode: google.maps.TravelMode.DRIVING
                };
                directionsService.route(request, function(response, status) {
                    if (status == google.maps.DirectionsStatus.OK) {
                        directionsDisplay.setDirections(response);
                    }
                });
            });
        }
        else {
            var start = {lat: user_lat, lng: user_long};
            var end = {lat: $track_lat, lng: $track_long};

            var request = {
                origin:start,
                destination:end,
                travelMode: google.maps.TravelMode.DRIVING
            };
            directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                }
            });
        }




        map2 = new google.maps.Map(document.getElementById('sat-map'), {
            center: {lat: $track_lat, lng: $track_long},
            mapTypeId: google.maps.MapTypeId.HYBRID,
            zoom: 17,
            mapTypeControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LOWER_RIGHT
            },
            scrollwheel: false,
            streetViewControl: false
        });
    }
    /** TRACK PROFILE PAGE END **/

    /** ADD TRACK MAP BEGIN **/
    if($page=='create') {

        map = new google.maps.Map(document.getElementById('map-container'), {
            center: {lat: user_lat, lng: user_long},
            mapTypeId: google.maps.MapTypeId.HYBRID,
            zoom: 9,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }

        });

        marker = new google.maps.Marker({
            position: new google.maps.LatLng({lat: user_lat, lng: user_long}),
            title:'Place On Track',
            map: map,
            draggable: true
        });

        marker.addListener('dragend', function() {
            $("#lat").val(marker.getPosition().lat());
            $("#long").val(marker.getPosition().lng());
        });

    }
    /** ADD TRACK MAP END **/

    /** UPDATE TRACK MAP BEGIN **/
    if($page=='update') {

        map = new google.maps.Map(document.getElementById('map-container'), {
            center: {lat: $track_lat, lng: $track_long},
            mapTypeId: google.maps.MapTypeId.HYBRID,
            zoom: 17,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
        });

        marker = new google.maps.Marker({
            position: new google.maps.LatLng({lat: $track_lat, lng: $track_long}),
            title:'Drag to Edit Position',
            map: map,
            draggable: true,
        });
        marker.addListener('dragend', function() {
            $("#lat").val(marker.getPosition().lat());
            $("#long").val(marker.getPosition().lng());
        });
    }
    /** UPDATE TRACK MAP END **/

}

//This function might be the key to moving a marker, study this when you're less tired
function setMapOnAll(map) {
  for (var i = 0; i < markers_map.length; i++) {
    markers_map[i].setMap(map);
  }
}

function clearMarkers() {
  setMapOnAll(null);
}

function get_icons(sidebar) {

    clearMarkers();

    //Determines the box boundary lines by the corners of the viewport
    var vp_top      = map_bounds.getNorthEast().lat(),
        vp_right    = map_bounds.getNorthEast().lng(),
        vp_bottom   = map_bounds.getSouthWest().lat(),
        vp_left     = map_bounds.getSouthWest().lng();

    //Passes Array of Viewport Bounds
    var json =  {
                    "top":vp_top,
                    "bottom":vp_bottom,
                    "left":vp_left,
                    "right":vp_right,
                    "SUN": FindSet.SUN,
                    "MON": FindSet.MON,
                    "TUE": FindSet.TUE,
                    "WED": FindSet.WED,
                    "THU": FindSet.THU,
                    "FRI": FindSet.FRI,
                    "SAT": FindSet.SAT
                };

    var url = "/viewport";

    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $.ajax(
        {
            url: url, dataType: 'json', type: 'POST', data: json,
            success: function(data, textStatus, XMLHttpRequest)
            {
                var icons       = data['markers']; //Array of all icons passed from controller
                var infowindow = new google.maps.InfoWindow();


                if(sidebar) {
                    var output = "<ul class='side-list'>";

                    for(var cc = 0; cc < icons.length; cc++) {
                        output +="<li class='info-win-side'>";
                        output+=icons[cc]['info_html']; //sidebar html being output and such
                        output +="</li>";
                    }
                    output+="</ul>";
                    $("#sidebar_inner").html(output);
                }

                for(cc = 0; cc < icons.length; cc++)
                {
                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng(icons[cc]['lat'],icons[cc]['long']),
                        title:icons[cc]['title'],
                        icon:"/img/track_bin_orange.png",
                        map: map
                    });

                    markers_map.push(marker);

                    google.maps.event.addListener(marker, 'click', (function(marker, cc) {
                        return function() {
                            infowindow.close();
                            infowindow.setContent(icons[cc]['info_html']);
                            infowindow.open(map, marker);
                        }
                    })(marker, cc));
                }
            },
            error: function(XMLHttpRequest, textStatus, errorThrown){
                alert('problem loading tracks');
            }

        });
    if(sidebar) {
        $("#sidebar_inner").html("<div style='margin-left:45px; margin-top:35vh; text-align:center;'><i class='fa fa-4x fa-refresh fa-spin'></i><br>loading listings</div>");
    }
}

function get_coords(address) {

    clearMarkers();

    var json ={"address":address};

    var url = "/geo_encode";

    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $.ajax(
    {
        url: url, dataType: 'json', type: 'POST', data: json,
        success: function(data, textStatus, XMLHttpRequest)
        { 

          var lat = data['geoLocation']['lat'];
          var long = data['geoLocation']['long'];

            $("#lat").val(lat);
            $("#long").val(long);

          marker = new google.maps.Marker({
                  position: new google.maps.LatLng(lat,long),
                  title: 'Drag me over the track!',
                  draggable: true,
                  map: map
                });
         
          map.panTo( new google.maps.LatLng( lat, long ) );
          markers_map.push(marker);

          marker.addListener('dragend', function() { 
            $("#lat").val(marker.getPosition().lat());
            $("#long").val(marker.getPosition().lng());
          });

          map.setZoom(17);

        },
        error: function(XMLHttpRequest, textStatus, errorThrown){}
    });
}

function get_weather(lat, long)
{
    var json ={"lat":lat,"long":long};

    var url = "/current_weather";

    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $.ajax(
        {
            url: url, dataType: 'json', type: 'POST', data: json,
            success: function(data, textStatus, XMLHttpRequest)
            {
                $("#currentIcon").html(data['icon']);
                $("#currentTemp").html(Math.round(data['temp']));
                $("#currentWind").html(data['wind']);
                $("#currentSummary").html(data['curr']);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown){
                if (counter > 5) {
                    forecast_weather();
                }
                weather_attempt ++;
            }
        });
}

function forecast_weather(lat, long)
{
    var json ={"lat":lat,"long":long};

    var url = "/forecast_weather";

    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $.ajax(
        {
            url: url, dataType: 'json', type: 'POST', data: json,
            success: function(data, textStatus, XMLHttpRequest)
            {
                $("#tomorrowIcon").html(data['icon_1']);
                $("#tomorrowTemp").html(Math.round(data['weather'].list[1].temp.max));
                $("#tomorrowTemp_min").html(Math.round(data['weather'].list[1].temp.min));
                $("#tomorrowSummary").html(data['weather'].list[1].weather[0].description);

                $("#day2Icon").html(data['icon_2']);
                $("#day2Temp").html(Math.round(data['weather'].list[2].temp.max));
                $("#day2Temp_min").html(Math.round(data['weather'].list[2].temp.min));
                $("#day2Summary").html(data['weather'].list[2].weather[0].description);

                $("#day3Icon").html(data['icon_3']);
                $("#day3Temp").html(Math.round(data['weather'].list[3].temp.max));
                $("#day3Temp_min").html(Math.round(data['weather'].list[3].temp.min));
                $("#day3Summary").html(data['weather'].list[3].weather[0].description);

                $("#day4Icon").html(data['icon_4']);
                $("#day4Temp").html(Math.round(data['weather'].list[4].temp.max));
                $("#day4Temp_min").html(Math.round(data['weather'].list[4].temp.min));
                $("#day4Summary").html(data['weather'].list[4].weather[0].description);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                if (forecast_attempt > 5) {
                    forecast_weather();
                }
                forecast_attempt ++;
            }
        });
}


//Admin Search Function
function findTrack() {

    var search_query,url,json,output;

    if ($('#a-input').val().length >= 3) {

        $('#search-results').css('display','inherit');

        search_query = $('#a-input').val();

        json ={"search_query":search_query};

        url = "/stracks";

        $.ajaxSetup({
            headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
        });

        $.ajax(
            {
                url: url, dataType: 'json', type: 'POST', data: json,
                success: function(data, textStatus, XMLHttpRequest)
                {
                    var tracks = data['list'];
                    var limit = tracks.length;

                    output = "<h3>Results for: "+search_query+"</h3>";
                    output += "<table class='table table-hover table-bordered'>";
                    output += "<thead>";
                    output += "<tr>";
                    output += "<th>Name</a></th>";
                    output += "<th>Address </th>";
                    output += "<th>City </th>";
                    output += "<th>State </th>";
                    output += "<th>Zip </th>";
                    output += "<th>Edit</th>";
                    output += "<th>Active</th>";
                    output += "<th>Delete</th>";
                    output += "</tr>";
                    output += "</thead>";
                    output += "<tbody>";

                    for(var cc = 0; cc < limit; cc++) {
                        output += "<tr>";
                        output +="      <td>";
                        output +="          <a href='/tracks/"+tracks[cc]['stub']+"'>";
                        output +=               tracks[cc]['name'];
                        output +="          </a>";
                        output +="      </td>";
                        output +="      <td>";
                        output +=          tracks[cc]['address'];
                        output +="      </td>";
                        output +="      <td>";
                        output +=          tracks[cc]['city'];
                        output +="      </td>";
                        output +="      <td>";
                        output +=          tracks[cc]['state'];
                        output +="      </td>";
                        output +="      <td>";
                        output +=          tracks[cc]['zip'];
                        output +="      </td>";
                        output +="      <td><a href='/update/"+tracks[cc]['stub']+"'>";
                        output +="      <button class='btn btn-primary'>Edit</button>";
                        output +="      </a></td>";
                        if(tracks[cc]['status']=='active'){
                            output +="      <td><a href='/status/"+tracks[cc]['id']+"/inactive'>";
                            output +="          <button class='btn btn-success'>Active</button>";
                            output +="      </a></td>";
                        }
                        else {
                            output +="      <td><a href='/status/"+tracks[cc]['id']+"/active'>";
                            output +="          <button class='btn btn-warning'>Inactive</button>";
                            output +="      </a></td>";
                        }
                        output +="      <td><a href='/delete/"+tracks[cc]['id']+"'>";
                        output += "          <button class='btn btn-danger'>Delete</button>";
                        output +="      </td>";
                        output +="</tr>";
                    }
                    output+="</tbody>";
                    output+="</table>";
                    $("#search-results").html(output);
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    $("#search-results").html('Error connecting to:' + url);
                }
            });
        // Loading message
        $("#search-results").html("<i class='fa fa-2x fa-refresh fa-spin'></i>");
    }
    else {
        $('#search-results').html('');
        $('#search-results').css('display','none');
    }
}

//NavBard Search Function
function navSearch() {

    var search_query,url,json,output;

    if ($('#nav-search').val().length >= 3) {

        $('#nav-search-results').css('display','inherit');

        search_query = $('#nav-search').val();


        json ={"search_query":search_query};

        url = "/stracks";

        $.ajaxSetup({
            headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
        });

        $.ajax(
            {
                url: url, dataType: 'json', type: 'POST', data: json,
                success: function(data, textStatus, XMLHttpRequest)
                {
                    var tracks = data['list'];
                    var limit = tracks.length;

                    output = "<div id='search-result-title'>MOTOCROSS TRACKS</div>";
                    output += "<ul id='search-list' class='list-unstyled'>";

                    for(var cc = 0; cc < limit; cc++) {
                        output+="<li>";
                        output +="          <a href='/tracks/"+tracks[cc]['stub']+"' class='search-result'>";
                        output +=               tracks[cc]['name'];
                        output +="          </a>";
                        output+="</li>";

                    }
                    output+="</ul>";

                    $("#nav-search-results").html(output);
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    $("#nav-search-results").html('Error connecting to:' + url);
                }
            });
        // Loading message
        $("#nav-search-results").html("<i class='fa fa-2x fa-refresh fa-spin'></i>");
    }

    else {
        $('#nav-search-results').html('');
        $('#nav-search-results').css('display','none');
    }
}



function instagram_gallery() {

    var json ={"instagram":instagram};

    var url = "/instagram";

    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $.ajax(
        {
            url: url, dataType: 'json', type: 'POST', data: json,
            success: function(data, textStatus, XMLHttpRequest)
            {
                var image_url = data[0]['images']['standard_resolution']['url'];

               $("#instagram_gallery").html("<img src='"+image_url +"' class='img-responsive'>");
            },
            error: function(XMLHttpRequest, textStatus, errorThrown){
                $("#instagram_gallery").html("Error loading Instagram gallery.");
            }
        });
}



$(function() {

    $("#direction_button").click(function() {
        if($("#portal").height()<1) {
            var portal_height = $(window).height()-$("#top-navbar").height()-$("#header_overlay").height();
            var om = $(window).height()-$("#header_overlay").height();
            $("#portal").animate({height: portal_height});
            $("#header_overlay").animate({top: om});
            $("#direction_button").html("<i class='fa fa-map'></i> Hide Map");
        }
        else {
            $("#portal").animate({height: '0px'});
            $("#header_overlay").animate({top: $("#top-navbar").height()});
            $("#direction_button").html("<i class='fa fa-map'></i> Show Map");
        }
    });


    $("#toggle-state-map").click(function() {

        if($("#state_map_overlay2").hasClass("showing-less")) {
            //Resize the overlay
            $("#state-map-container").animate({height: $(window).height() - $("#state_map_overlay2").height()}, 500);
            $("#toggle-state-map").html("<i class='fa fa-map'></i> Hide Map");
            $("#state_map_overlay2").removeClass("showing-less")
        }
        else {
            $("#state_map_overlay2").animate({height: 200}, 500);
            $("#state-map-container").animate({height: 0}, 500);
            $("#toggle-state-map").html("<i class='fa fa-map'></i> Show Map");
            $("#state_map_overlay2").addClass("showing-less")
        }
    });

//Toggle direction div expand/contract to show all steps in route
    $("#toggle-directions").click(function() {
        if($("#directionsDiv").hasClass("showing-less")) {
            $("#directionsDiv").removeClass("showing-less");
            $("#toggle-directions").html("Show Less");
        }
        else {
            $("#directionsDiv").addClass("showing-less");
            $("#toggle-directions").html("Show More");
        }
    });


//Toggle sidebar menu in and out of view
    $( "#toggle_button").click(function() {
        if(!$("#sidebar_container" ).hasClass( "we_hidden" )) {
            $('#sidebar_container').animate({ "left": "-=375px" }, 500 );
            $('#toggle_button').animate({ "left": "-=375px" }, 500 );
            $( "#toggle_arrow" ).removeClass( "fa-angle-left" ).addClass( "fa-angle-right" );
            $('#sidebar_container').addClass("we_hidden");
        }
        else {
            $('#sidebar_container').animate({ "left": "+=375px" }, 500 )
            $('#toggle_button').animate({ "left": "+=375px" }, 500 );
            $( "#toggle_arrow" ).removeClass( "fa-angle-right" ).addClass( "fa-angle-left" );
            $('#sidebar_container').removeClass("we_hidden");
        }
    });

//Slide mobile vertical up and down
    $( "#mobile_ribbon").click(function() {
        var navs = $('#top_nav_bar').height() + $('#filter-bar').height();
        if(!$("#sidebar_container" ).hasClass( "list_up" )) {
            $('#sidebar_container').animate({ "top": navs }, 500 );
            $('#sidebar_container').addClass("list_up");
            $("#mobile_ribbon").html("Hide List");
        }
        else {
            $('#sidebar_container').animate({ "top": screen.height }, 500 );
            $('#sidebar_container').removeClass("list_up");
            $("#mobile_ribbon").html("See List");
        }
    });

    $("#setPin").click(function() {
        if($("#address").val() && $("#city").val() && $("#state").val() && $("#zip").val()) {
          var address = $("#address").val()+' '+$("#city").val()+' '+$("#state").val()+' '+$("#zip").val();
          get_coords(address);
        }
    });





    $('#jMap').usmap({'stateStyles': {fill: '#337AB7', "stroke-width": 1, 'stroke' : '#444'},
                      'stateHoverStyles': {fill: '#23527c'},
        click: function(event, data) {
                    window.location = '/tracks/in/'+data.name;
        }
  });

    $('#nav-search-btn').click( function () {
        if ($('#nav-search').val().length >= 3) {
            navSearch();
        }
        else {
            $('#nav-search-results').css('display','none');
        }
    });

    $('#nav-search').keyup( function () {
        if ($('#nav-search').val().length >= 3) {
            navSearch();
        }
        else {
            $('#nav-search-results').css('display','none');
        }
    });



    //DAYS OPEN FILTERS
    $("#openSUN").click(function() {
        if(FindSet.SUN == 1) {
            FindSet.SUN = 0;
            $("#openSUN").removeClass('filter_on');
        }
        else {
            FindSet.SUN = 1;
            $("#openSUN").addClass('filter_on');
        }
        get_icons(true);
    });

    $("#openMON").click(function() {
        if(FindSet.MON == 1) {
            FindSet.MON = 0;
            $("#openMON").removeClass('filter_on');
        }
        else {
            FindSet.MON = 1;
            $("#openMON").addClass('filter_on');
        }
        get_icons(true);
    });

    $("#openTUE").click(function() {
        if(FindSet.TUE == 1) {
            FindSet.TUE = 0;
            $("#openTUE").removeClass('filter_on');
        }
        else {
            FindSet.TUE = 1;
            $("#openTUE").addClass('filter_on');
        }
        get_icons(true);
    });

    $("#openWED").click(function() {
        if(FindSet.WED == 1) {
            FindSet.WED = 0;
            $("#openWED").removeClass('filter_on');
        }
        else {
            FindSet.WED = 1;
            $("#openWED").addClass('filter_on');
        }
        get_icons(true);
    });

    $("#openTHU").click(function() {
        if(FindSet.THU == 1) {
            FindSet.THU = 0;
            $("#openTHU").removeClass('filter_on');
        }
        else {
            FindSet.THU = 1;
            $("#openTHU").addClass('filter_on');
        }
        get_icons(true);
    });

    $("#openFRI").click(function() {
        if(FindSet.FRI == 1) {
            FindSet.FRI = 0;
            $("#openFRI").removeClass('filter_on');
        }
        else {
            FindSet.FRI = 1;
            $("#openFRI").addClass('filter_on');
        }
        get_icons(true);
    });

    $("#openSAT").click(function() {
        if(FindSet.SAT == 1) {
            FindSet.SAT = 0;
            $("#openSAT").removeClass('filter_on');
        }
        else {
            FindSet.SAT = 1;
            $("#openSAT").addClass('filter_on');
        }
        get_icons(true);
    });


    //Admin search button pressed
    $('#aSearch').click( function() {
            findTrack();
        }
    );
    //Admin search key ajax
    $('#a-input').keyup( function () {
        if ($('#a-input').val().length >= 3) {
            findTrack();
        }
    });


    $(document).on('change', ':file', function() {
        var input = $(this),
            numFiles = input.get(0).files ? input.get(0).files.length : 1,
            label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
        input.trigger('fileselect', [numFiles, label]);
    });

    $(document).ready( function() {
        $(':file').on('fileselect', function(event, numFiles, label) {
            var input = $(this).parents('.input-group').find(':text'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;
            if( input.length ) {
                input.val(log);
            }
        });
    });

//GA
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    ga('create', 'UA-83427950-1', 'auto');
    ga('send', 'pageview');


});



